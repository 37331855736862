import React, {useState} from 'react'
import { RichText } from 'prismic-reactjs'
import LogoImage from '../../images/Wild-Meadow-Honey-logo-white.png'

const Banner = ({ slice }) => {
  const [isActive, setIsActive] = useState(false)

  const toggleActive = () => {
    setIsActive(!isActive)
  }
  return (
  <section
    class="hero is-medium"
    style={{
      backgroundImage: `linear-gradient(rgba(157, 132, 32, 0.4), rgba(157, 132, 32, 0.8)), url(${slice.primary.background.url})`,
      backgroundPosition: `40% 40%`,
    }}
  >
    <div class="hero-head">
      <nav class="navbar">
        <div class="navbar-brand">
          <a class="navbar-item" href="/">
            <img src={LogoImage} alt="Logo"/>
          </a>
          <div class={`navbar-burger burger ${isActive ? 'is-active' : ''}`} data-target="navbarMenuHeroA" onClick={toggleActive}>
            <span key="1"></span>
            <span key="2"></span>
            <span key="3"></span>
          </div>
        </div>
        <div id="navbarMenuHeroA" class={`navbar-menu ${isActive ? 'is-active' : ''}`} onClick={toggleActive}>
          <div class="navbar-end">
            <a class="navbar-item" href="/">
              Home
            </a>
            {slice.items.map((linkItem, index) => (
              <a class="navbar-item" href={linkItem.link.url}>
                {RichText.asText(linkItem.link_label.raw)}
              </a>
            ))}
            <div class="navbar-item">
              <a class="button is-primary" href={slice.primary.main_link.url}>
                <span class="icon">
                  <i class="fa fa-store"></i>
                </span>
                <span>{RichText.asText(slice.primary.main_link_label.raw)}</span>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <div class="hero-body">
      <div class="container">
        <div class="title has-text-white has-text-centered is-size-2 is-size-5-mobile">
          {slice.primary.description.text}
        </div>
      </div>
      <div dangerouslySetInnerHTML={{__html: slice.primary.background.copyright}}/>
    </div>
  </section>)
}

export default Banner
