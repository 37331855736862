import React from "react";
import { Formik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup.string("Enter you name").required("Name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  message: yup.string("Enter your message").required("Message is required"),
});

const Contact = () => {
  return (
    <div class="columns is-centered">
      <div class="column is-half">
        <div class="title has-text-centered is-size-2 is-size-5-mobile">
          Contact Form
        </div>
        <Formik
          initialValues={{
            name: "",
            email: "",
            message: "",
          }}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <form name="contact" netlify-honeypot="cevc-bot-field-test" data-netlify="true">
              <input type="hidden" name="form-name" value="contact" />
              <div class="is-hidden">
                <label>D0n’t fi11 this 0ut if y0u’r3 human: <input name="cevc-bot-field-test" /></label>
              </div>
              <div class="field">
                <label htmlFor="name" class="label">
                  Name
                </label>
                <div class="control has-icons-left">
                  <input
                    class={`input ${
                      Boolean(formik.errors.name)
                        ? "is-danger"
                        : ""
                    } `}
                    type="text"
                    name="name"
                    id="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {!Boolean(formik.errors.name) && (
                    <span class="icon is-small is-left">
                      <i class="fa fa-user"></i>
                    </span>
                  )}
                  {Boolean(formik.errors.name) && (
                    <span class="icon is-small is-left">
                      <i class="fas fa-exclamation-triangle"></i>
                    </span>
                  )}
                  {Boolean(formik.errors.name) ? (
                    <p class="help is-danger">Name is required</p>
                  ) : null}
                </div>
              </div>

              <div class="field">
                <label htmlFor="email" class="label">
                  Email
                </label>
                <div class="control has-icons-left ">
                  <input
                    class={`input ${
                      Boolean(formik.errors.email)
                        ? "is-danger"
                        : ""
                    } `}
                    type="email"
                    name="email"
                    id="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {!Boolean(formik.errors.email) && (
                    <span class="icon is-small is-left">
                      <i class="fa fa-envelope"></i>
                    </span>
                  )}
                  {Boolean(formik.errors.email) && (
                    <span class="icon is-small is-left is-danger">
                      <i class="fa fa-exclamation-triangle"></i>
                    </span>
                  )}
                  {Boolean(formik.errors.email) ? (
                    <p class="help is-danger">This email is invalid</p>
                  ) : null}
                </div>
              </div>

              <div class="field">
                <label htmlFor="message" class="label">
                  Message
                </label>
                <div class="control">
                  <textarea
                    class="textarea"
                    type="text"
                    name="message"
                    id="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>

              <div class="field">
                <label htmlFor="button" class="label">
                  Message
                </label>
                <div class="control">
                  <button id="button" class="button" type="submit">
                    Send
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Contact;
